.gpt3__posibility {
    display: flex;
    flex-direction: row;
}

.gpt3__posibility-image {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    margin-right: 2rem;
}

.gpt3__posibility-image img {
    width: 100%;
    height: 100%;
}

.gpt3__posibility-content {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    flex-direction: column;
}

.gpt3__posibility-content h4 {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    color: #71E5FF;
}

.gpt3__posibility-content h4:last-child {
    color: #ff8A71;
}

.gpt3__posibility-content h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 34px;
    line-height: 45px;
    margin: 1rem 0;
}

.gpt3__posibility-content p {
    color: var(--color-text);
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;

    margin-bottom: 2rem;
}

@media screen and (max-width: 950px) {
    .gpt3__posibility {
        flex-direction: column;
    }

    .gpt3__posibility-image {
        margin: 1rem 0;
    }

    .gpt3__posibility-image img {
        width: unset;
        height: unset;
    }

    .gpt3__posibility-content {
        margin-top: 2rem;
    }
}

@media screen and (max-width: 700px) {
    .gpt3__posibility-image img {
        width: 100%;
        height: 100%;
    }
}